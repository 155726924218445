<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              Affecter une formation
              <router-link to="formations">
                <span class="float-right btn btn-info"> retour </span>
              </router-link>
            </div>
            <div class="container">
              <form @submit.prevent="addformation()" novalidate >
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="first"
                        >Theme <span class="obligatoire">*</span>
                      </label>

                      <input
                        type="text"
                        class="form-control"
                        placeholder="theme de formation"
                        id="theme"
                        v-model="theme"
                        required
                      />
                    </div>
                  </div>

          <div class="form-group">
            <label for="last"
                        >Plan<span class="obligatoire"
                          >*</span
                        ></label>
            <div class="input-group col-md-14">
              <input
                type="file"
                class="form-control file-upload-info"
                placeholder="Upload Image"
                @change="uploadFile"
                ref="file"
              /> 
                  
              <span class="input-group-append">
                <button
                  class="file-upload-browse btn btn-gradient-primary"
                  type="button"
                >
                  Télécharger
                </button>
              </span>
            </div>
          </div> 
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="last"
                        >Adresse Formateur<span class="obligatoire"
                          >*</span
                        ></label
                      >
                      <input
                        type="email"
                        class="form-control"
                        placeholder="adresse"
                        id="adresse"
                        v-model="adresse"
                        required
                      />
                    </div>
                  </div>
                  <!--  col-md-6   -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="last"
                        >Nb de jour <span class="obligatoire">*</span></label
                      >
                      <input
                        type="number"
                        class="form-control"
                        placeholder="nb de jour"
                        id="last"
                        v-model="nbjour"
                        required
                      />
                    </div>
                  </div>



                  
                  <!--  col-md-6   -->

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="last"
                        >Date Debut <span class="obligatoire">*</span></label
                      >
                      <input
                        type="date"
                        class="form-control"
                        placeholder="Date Debut"
                        id="date_debut"
                        v-model="date_debut"
                        required
                      />
                    </div>
                  </div>
                  <!--  col-md-6   -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="last"
                        >Date Fin <span class="obligatoire">*</span></label
                      >
                      <input
                        type="date"
                        class="form-control"
                        placeholder="date"
                        id="last"
                        v-model="date_fin"
                        required
                      />
                    </div>
                  </div>


                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="last"
                        >Heure <span class="obligatoire">*</span></label
                      >
                      <input
                        type="time"
                        class="form-control"
                        placeholder="Date Debut"
                        id="heure"
                        v-model="heure"
                        required
                      />
                    </div>
                  </div>
                  <!--  col-md-6   -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="last"
                        >Durée <span class="obligatoire">*</span></label
                      >
                      <input
                        type="number"
                        class="form-control"
                        placeholder="dureé"
                        id="last"
                        v-model="duree"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-md-6" id="delegue" style="display: none">
                    <div class="form-group">
                      <label for="last"
                        >Délégué <span class="obligatoire">*</span></label
                      >
                      <select
                        id="inputState"
                        class="form-control"
                        v-model="user_id"
                        required
                      >
                        <option
                          v-for="user in users"
                          :key="user._id"
                          v-bind:value="user._id"
                        >
                          {{ user.name }}
                        </option>
                      </select>
                    </div>
                  </div> 


                  <div class="col-md-6" id="groupe" style="display: none">
                    <div class="form-group">
                      <label for="last"
                        >Groupe <span class="obligatoire">*</span></label
                      >
                      <select
                        id="inputState"
                        class="form-control"
                        v-model="group_id"
                        required
                      >
                        <option
                          v-for="groupe in groups"
                          :key="groupe._id"
                          v-bind:value="groupe._id"
                        >
                          {{ groupe.name }}
                        </option>
                      </select>
                    </div>
                  </div> 

                  

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="first"
                        >Emplacement <span class="obligatoire">*</span></label
                      >
                      <select
                          class="form-control"
                          id="exampleSelect"
                          v-model="emplacement"
                        >
                          <option value="En ligne">En ligne</option>
                          <option value="Présentielle">Présentielle</option>
                        </select>
                     
                    </div>
                  </div>


                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="first"
                        >Membre <span class="obligatoire">*</span></label
                      >
                      <select
                          class="form-control"
                          id="exampleSelect"
                          v-model="membre"
                          @change="onSelectChange(membre)"
                        >
                          <option value="Par personne">Par personne</option>
                          <option value="Par groupe">Par groupe</option>
                        </select>
                     
                    </div>
                  </div>


                  <!-- <div class="col-md-6" id="registre" style="display: none">
                    <div class="form-group">
                      <label for="first"
                        >Contenu <span class="obligatoire">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="contenu"
                        id="first"
                        v-model="formation.contenu"
                        required
                      />
                    </div>
                  </div> -->
                  <!--  col-md-6   -->

                  <!--  col-md-6   -->
                </div>

                <button type="submit" class="btn btn-primary">
                  + Affecter la formation
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { HTTP } from "@/axios";
import Swal from "sweetalert2";
import Toast from "sweetalert2";
import moment from "moment";
export default {
  data() {
    return {
      formation: {
        theme: "",
        date_debut: "",
        date_fin: "",
        nbjour: "",
        user_id: "",
        group_id: "",
        adresse: "",
        emplacement: "",
        description: "",
        membre: "",
        file: "",
        plan: "",
        heure: "",
        duree: "",
      },
      users: [],
      groups: [],

      error: "",
    };
  },

  created() {
    this.getusers();
    this.getgroups();

  },
  methods: {
    uploadFile () {
        this.file = this.$refs.file.files[0];
        console.log(this.file);
        
        },
    getusers() {
      HTTP.get("users/getusers")
        .then((response) => {
          console.log(response.data);
          this.users = response.data;
        })
        .then((err) => console.log(err));
    },
    getgroups() {
      HTTP.get("groupes/getgroups")
        .then((response) => {
          console.log(response.data);
          this.groups = response.data;
        })
        .then((err) => console.log(err));
    },

    addformation() {

      const formData = new FormData();
        formData.append('plan', this.file)
        formData.append('theme', this.theme)
        formData.append('adresse', this.adresse)
        formData.append('date_debut', this.date_debut)
        formData.append('date_fin', this.date_fin)
        formData.append('nbjour', this.nbjour)
        formData.append('user_id', this.user_id)
        formData.append('group_id', this.group_id)
        formData.append('emplacement', this.emplacement)
        formData.append('membre', this.membre)
        formData.append('heure', this.heure)
        formData.append('duree', this.duree)

      HTTP.post("formations/addformation", formData).then(
        (res) => {
          if (res.status === 200) {
            Toast.fire({
              icon: "success",
              title: "formation ajouté",
            });
            this.$router.push("/formations");
          }
        },
        (err) => {
          this.error = err.response.data;
        }
      );
    },

    onSelectChange(data) {
      if (data === "Par groupe") {
        document.getElementById("groupe").style.display = "block";
        document.getElementById("delegue").style.display = "none";
      } else if (data === "Par personne") {
        document.getElementById("groupe").style.display = "none";
        document.getElementById("delegue").style.display = "block";
      }
    },
  },
};
</script>
